.container {
}
.main {
  display: flex;
  > div {
    flex: 1;
  }
  > div:nth-of-type(1) {
    padding: 15px;
    > .titleStep {
      display: flex;
      justify-content: space-between;
      > div:nth-of-type(1) {
        font-weight: bolder;
        font-size: 1.2rem;
      }
      > div:nth-of-type(2) {
        margin-right: 10px;
        > span:nth-child(1) {
          margin-right: 10px;
        }
        > span:nth-of-type(2) {
          margin-right: 5px;
        }
        > span:nth-of-type(2),
        > span:nth-of-type(3) {
          display: inline-block;
          width: 30px;
          text-align: center;
          font-size: 0.9rem;
          &::after {
            content: ' ';
            display: block;
            background-color: #d9d9d9;
            height: 3px;
            width: 100%;
          }
        }
        > .active {
          &::after {
            background-color: #428dff !important;
          }
        }
      }
    }
    > .desc {
      color: rgba(2, 24, 58, 0.6);
      line-height: 1.4rem;
      margin-top: 0.5rem;
      font-size: 0.9rem;
    }
    > .platformsItem {
      color: #a4a5a7;
      margin: 1rem 0;
    }
    > .modalInput {
    }
    > .plateforms {
      > .addPlatform {
        display: flex;
        padding: 0.8rem 1rem;
        background-color: #f1f7fb;
        justify-content: space-between;
        border-radius: 5px;
        align-items: center;
        > div:nth-of-type(1) {
          > img {
            height: 1rem;
            vertical-align: middle;
            margin-right: 10px;
            float: left;
            margin-top: 8px;
          }
          > span {
            vertical-align: sub;
            font-size: 1rem;
          }
        }
      }
    }
    > .account {
      border: 1px solid #e3e3e3;
      padding: 0.5rem;
      border-radius: 5px;
      height: 7rem;
      position: relative;
      > .iptAction {
        display: flex;
        > .ipt {
          border: none;
          padding: 0 1px;
          color: #ff47aa;
          font-size: 0.9rem;
          height: 1.5rem;
          &:focus {
            box-shadow: none;
          }
        }
        > .ok {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          background-color: #4bc985;
          border-radius: 15px;
          padding: 0.1rem 0.5rem;
          cursor: pointer;
          > span {
            color: #ffffff;
            margin-left: 5px;
          }
          &:hover {
            background-color: #1bec7c;
          }
        }
      }
      > .loading {
        > div:nth-of-type(1) {
          margin-top: 1rem;
          text-align: center;
          font-size: 0.9rem;
        }
        > div:nth-of-type(2) {
          background-color: #f1f7fb;
          width: 70%;
          text-align: center;
          margin: auto;
          margin-top: 0.7rem;
          padding: 5px 2rem;
          border-radius: 10px;
          img {
            margin-right: 10px;
            vertical-align: sub;
            animation: scroll infinite 1s linear;
          }
          span {
            color: #02183a;
            font-size: 0.9rem;
          }
        }
      }
      > .info {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        height: 100%;
        width: 100%;
        > .left {
          display: flex;
          > div:nth-of-type(1) {
            height: 50px;
            width: 50px;
            background-color: #d9d9d9;
            border-radius: 50px;
            margin-right: 10px;
            position: relative;
            img:nth-of-type(1) {
              // height: 20px;
              height: 100%;
              width: 100%;
              border-radius: 50px;
            }
            > img:nth-of-type(2) {
              position: absolute;
              height: 15px;
              width: 15px;
              right: 0px;
              bottom: 0px;
            }
          }
          > div:nth-of-type(2) {
            > div:nth-of-type(1) {
              font-weight: bolder;
            }
            > div:nth-of-type(2) {
              width: 13rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 0.9rem;
              color: #748093;
            }
          }
        }
        > .right {
          cursor: pointer;
        }
      }
    }
    .chanceRadio {
      display: flex;
      gap: 20px;
      margin-top: 10px;
      > div {
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        > div:nth-of-type(1) {
          height: 18px;
          width: 18px;
          background-color: #dbdbdb;
          border-radius: 50%;
          position: relative;
          &::before {
            position: absolute;
            content: ' ';
            height: 8px;
            width: 8px;
            background-color: #ffffff;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        > div:nth-of-type(2) {
          padding-top: 2px;
        }
      }
    }
    .newDesc {
      color: rgba(2, 24, 58, 0.6);
      margin-top: 1rem;
      font-size: 0.9rem;
    }
    .copyDesc {
      justify-content: space-between;
      display: flex;
      padding: 1rem;
      border-radius: 5px;
      margin: 20px 0 11rem 0;
      color: #000000;
      background-color: #f1f7fb;
      > div:nth-of-type(1) {
        font-size: 0.9rem;
        width: 80%;
        font-weight: bolder;
        img {
          vertical-align: sub;
          margin-right: 5px;
        }
      }
    }
    .copyDesc1 {
      justify-content: space-between;
      display: flex;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin: 15px 0 calc(7rem - 18px) 0;
      color: #000000;
      background-color: #f1f7fb;
      > div:nth-of-type(1) {
        font-size: 0.9rem;
        width: 80%;
        font-weight: bolder;
        img {
          vertical-align: sub;
          margin-right: 5px;
        }
      }
    }
    .platforms {
      > li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e3e3e3;
        padding: 0.4rem;
        border-radius: 5px;
        margin-top: 15px;
        img {
          height: 20px;
          margin-right: 5px;
        }
        span {
          font-size: 0.9rem;
        }
        &:hover {
          span {
            color: #418dff;
          }
        }
      }
    }

    > .btn_c1 {
      display: flex;
      gap: 0.5rem;
      display: flex;
      gap: 0.5rem;
      margin-top: 3rem;
      button {
        width: 50%;
        font-size: 0.8rem;
        height: 2.3rem;
      }
      > button:nth-of-type(1) {
        color: #02183a;
      }
      > button:nth-of-type(2) {
        background-color: #02183a;
        color: #ffffff;
      }
    }
    > .btn_c2 {
      display: flex;
      gap: 0.5rem;
      display: flex;
      gap: 0.5rem;
      margin-top: 4rem;
      button {
        width: 50%;
        font-size: 0.8rem;
        height: 2.3rem;
      }
      > button:nth-of-type(1) {
        color: #02183a;
      }
      .copy {
        background: linear-gradient(270deg, #ff47aa 0%, #aa56ff 30%, #418dff 80%), #02183a;
        color: #ffffff;
      }
      .noCopy {
        background-color: #02183a;
        color: #ffffff;
      }
    }
  }
}

.disclaimer {
  padding: 0.5rem;
  > .title {
    text-align: center;
    font-weight: bolder;
    color: #02183a;
    font-size: 1.1rem;
  }
  > .desc {
    margin-top: 0.5rem;
    color: #8993a3;
    line-height: 1.1rem;
    font-weight: 400;
    text-align: left;
  }
  .btns {
    display: flex;
    gap: 0.5rem;
    margin-top: 2.5rem;
    button {
      width: 50%;
      font-size: 0.8rem;
      height: 2.3rem;
    }
    > button:nth-of-type(1) {
      color: #02183a;
    }
    > button:nth-of-type(2) {
      background-color: #02183a;
      color: #ffffff;
    }
  }
}

@keyframes scroll {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 900px) and (min-width: 576px) {
  .main {
    > div:nth-of-type(1) {
      > .desc {
        font-size: 0.9rem;
      }
      > .account {
        > .loading {
          > div:nth-of-type(1) {
            font-size: 0.8rem;
          }
          > div:nth-of-type(2) {
            width: 75%;
            > span {
              font-size: 0.8rem;
            }
          }
        }
        > .info {
          > .left {
            > div:nth-of-type(2) {
              width: 12rem;
              > div:nth-of-type(1) {
                margin-top: 0.5rem;
              }
              > div:nth-of-type(2) {
                width: 10rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .main {
    > div:nth-of-type(1) {
      > .desc {
        font-size: 0.9rem;
      }
      > .account {
        > .loading {
          > div:nth-of-type(1) {
            font-size: 0.8rem;
          }
          > div:nth-of-type(2) {
            width: 75%;
            > span {
              font-size: 0.8rem;
            }
          }
        }
        > .info {
          > .left {
            > div:nth-of-type(2) {
              width: 12rem;
              > div:nth-of-type(1) {
                margin-top: 0.5rem;
              }
              > div:nth-of-type(2) {
                width: 10rem;
              }
            }
          }
        }
      }
    }
  }
}

